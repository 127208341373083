Ember.TEMPLATES["javascripts/discourse/connectors/admin-user-controls-after/download-all-wrapper"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showAdminUserExport\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"actionParam\",\"label\",\"icon\"],[\"exportAdminUserArchive\",[24,[\"model\"]],\"user.download_archive.button_text\",\"download\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/admin-user-controls-after/download-all-wrapper"}});
define("discourse/plugins/discourse-legal-tools/discourse/connectors/admin-user-controls-after/download-all-wrapper", ["exports", "discourse/lib/export-csv"], function (_exports, _exportCsv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent: function setupComponent(attrs, component) {
      var setting = component.siteSettings.legal_extended_user_download_admin;
      var user = component.currentUser;

      var allowed = function (setting) {
        switch (setting) {
          case 'disabled':
            return false;

          case 'admins_only':
            return user.admin;

          case 'admins_and_staff':
            return user.staff;

          default:
            return false;
        }
      }(setting);

      component.set('showAdminUserExport', allowed);
    },
    actions: {
      exportAdminUserArchive: function exportAdminUserArchive(user) {
        bootbox.confirm(I18n.t("user.download_archive.confirm_all_admin", {
          username: user.username
        }), I18n.t("no_value"), I18n.t("yes_value"), function (confirmed) {
          return confirmed ? (0, _exportCsv.exportEntity)('admin_user_archive', {
            user_id: user.id
          }) : null;
        });
      }
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-legal-tools/discourse/initializers/legal-edits", ["exports", "discourse/lib/plugin-api", "discourse/lib/export-csv"], function (_exports, _pluginApi, _exportCsv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'legal-edits',
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)('0.8.12', function (api) {
        api.modifyClass('controller:user-activity', {
          actions: {
            exportUserArchive: function exportUserArchive() {
              var extendedUserDownload = this.siteSettings.legal_extended_user_download;

              if (extendedUserDownload) {
                bootbox.confirm(I18n.t("user.download_archive.confirm_all"), I18n.t("no_value"), I18n.t("yes_value"), function (confirmed) {
                  return confirmed ? (0, _exportCsv.exportUserArchive)() : null;
                });
              } else {
                this._super();
              }
            }
          }
        });
      });
    }
  };
  _exports.default = _default;
});

